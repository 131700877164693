import React from 'react';
import FooterStore from './store';
import { Footer } from './GlobalFooterWidget';
import { widget, globalStore } from '@widgets/core';
var pageScrollAnimate = function (destination, callback) {
    var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    var destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    var destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }
    var scroll = function () {
        var fixedOffset = destinationOffsetToScroll.toFixed();
        var onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                setFocus();
            }
        };
        window.addEventListener('scroll', onScroll);
        onScroll();
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };
    scroll();
};
var setFocus = function () {
    globalStore.globalHeaderWrapper && globalStore.globalHeaderWrapper.focus();
};
var scrollToGlobalHeader = function (e) {
    e.preventDefault();
    var ms = 200;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    ms = (scrollTop * ms) / 800;
    pageScrollAnimate(0, setFocus);
};
export var FooterWidget = widget('footer')(function () { return new FooterStore(); }, function (store, tracker) {
    return (React.createElement(Footer, { showBackToTopLink: store.config.showBackToTopLink, showLinks: store.config.showLinks, programLinks: store.programLinks, organizationLinks: store.organizationLinks, extraLinks: store.extraLinks, globalHeaderId: globalStore.globalHeaderWrapper ? globalStore.globalHeaderWrapper.id : '', topClicked: scrollToGlobalHeader, tracker: tracker }));
});
export default FooterWidget;
